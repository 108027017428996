import config from '../config'
import Cookies from 'cookies-js'
import 'whatwg-fetch'

function savePost(post) {
  return fetch(`${config.postCreator}/save`, {
    body: JSON.stringify(post),
    headers: {
      'authorization': `Bearer ${Cookies.get('token')}`,
      'content-type': 'application/json'
    },
    method: 'POST',
    mode: 'cors'
  })
  .catch(() => {
    throw {text: 'Post could not be saved'}
  })
  .then(res => {
    if (res.status === 401)
      return {status: res.status, statusText: res.statusText, body: {message: 'User not authenticated '}}
    return res.json().then(json => ({status: res.status, statusText: res.statusText, body: json}))
  })
  .then(output => {
    if (output.status !== 200)
      throw {
        status: output.status,
        text: (!output.body || !output.body.message) ? output.statusText : output.body.message
      }
    return output.json
  })
}

function deletePost(postId) {
  console.log("API deletePost called, postId:", postId); // Add this line
  return fetch(`${config.postCreator}/delete/${postId}`, {
    headers: {
      'authorization': `Bearer ${Cookies.get('token')}`
    },
    method: 'DELETE',
    mode: 'cors'
  })
  .catch((error) => {
    console.error("Error in API deletePost:", error); // Add this line
    throw {text: 'Post could not be deleted'}
  })
  .then(res => {
    if (res.status === 401)
      return {status: res.status, statusText: res.statusText, body: {message: 'User not authenticated '}}
    if (res.status !== 200)
      throw {
        status: res.status,
        text: res.statusText
      }
  })
}

export default {savePost, deletePost}
