import LegacyRedirect from "../LegacyRedirect";
import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import AuthenticatedRoute from "../shared/AuthenticatedRoute";
import "../App/App.css";
import DocumentTitle from "react-document-title";
import ReactGA from "react-ga";
import { Suspense, lazy } from "react";
import AppHeader from "../AppHeader";
import AppFooter from "../AppFooter";
import LoginPage from "../AuthControl/LoginPage"; //dont lazy load
/* import ImgUpload from "../ImgUpload"; */
const PostReader = React.lazy(() => import("../PostReader/index"));
const Rules = React.lazy(() => import("../Rules"));
const Credits = React.lazy(() => import("../Credits"));
const WelcomePage = React.lazy(() => import("../WelcomePage"));
const PrivacyPage = React.lazy(() => import("../PrivacyPage"));
const changelog = React.lazy(() => import("../changelog"));
const DMCA = React.lazy(() => import("../DMCA"));
const TOS = React.lazy(() => import("../TOS"));
const Taglist = React.lazy(() => import("../taglist"));
const resources = React.lazy(() => import("../resources"));
const PostManagement = React.lazy(() => import("../PostManagement")); //dont lazy load authentication depends on this and login page
const RegistrationPage = React.lazy(() =>
  import("../AuthControl/RegistrationPage")
);
const UserPage = React.lazy(() => import("../UserPage"));
const SinglePostViewer = React.lazy(() => import("../SinglePostViewer"));
const SearchBox = React.lazy(() => import("../SearchBox"));

const App = () => {
  // ReactGA.initialize("UA-175491751-1");
  // ReactGA.pageview(window.location.pathname + window.location.search);
  // ReactGA.set({ anonymizeIp: true });
  return (
    <DocumentTitle title={"Open TGC"}>
      <div className={"appWrapper"}>
        <AppHeader />
        <React.Suspense fallback={<p>loading</p>}>
          <Switch>
            <Route path="/reader" component={PostReader} />
            <Route path="/search" component={SearchBox} />
            <Route path="/posts/:page?" component={LegacyRedirect} />
            <Route
              path="/authors/:author/posts/:page?"
              component={LegacyRedirect}
            />
            <Route path="/tags/:tag/posts/:page?" component={LegacyRedirect} />
            <Route path="/post/:postId" component={SinglePostViewer} />
            <Route path="/login" component={LoginPage} />
            <Route path="/privacy" component={PrivacyPage} />
            <Route path="/DMCA" component={DMCA} />
            <Route path="/TOS" component={TOS} />
            <Route path="/rules" component={Rules} />
            <Route path="/Credits" component={Credits} />
            <Route path="/changelog" component={changelog} />
            <Route path="/taglist" component={Taglist} />
            <Route path="/resources" component={resources} />
            <Route
              path="/discord"
              component={() => {
                window.location = "http://discord.com/invite/7CFseUF";
                return null;
              }}
            />
            <Route
              path="/img"
              component={() => {
                window.location = "http://discord.com/invite/7CFseUF";
                return null;
              }}
            />
            <Route path="/register" component={RegistrationPage} />
            <AuthenticatedRoute
              path={"/postmgmt/:postId?"}
              component={PostManagement}
            />
            <AuthenticatedRoute path={"/myAccount"} component={UserPage} />
            <Route path="/" component={WelcomePage} />
            <Redirect from="*" to="/" />
          </Switch>
        </React.Suspense>
        <AppFooter />
      </div>
    </DocumentTitle>
  );
};
export default App;
